import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { homePagePaths } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import { LanguageContext } from "../context"
import Button from "../components/button-generic"
import Filters from "../components/search/filters"
import AuthorCard from "../utils/blog/author-card"
import SharePostSocialMedia from "../utils/social-media/share"
//import SimilarItems from "../components/blog/similar-items"
import DrupalComments from '../../plugins/gatsby-plugin-drupal-comments-unida/src'
import Icon from "../components/icon"
import moment from "moment"
import 'moment/min/locales'
import Utils from "../utils"
import BlogItem from "../components/blog/sidebar-blog-item"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Seo from "../components/seo"


const PageEvent = ({
    data,
    location,
    pageContext,
    categories,
    refreshList,
    initialQuery
  }) => {
    const post = data?.nodeEventGoel
    const recommendeds = data?.recommendeds
    const { t } = useContext(LanguageContext)
    const fullUrl = typeof window !== 'undefined' ? window.location.href : '';
    const breakpoints = useBreakpoint()

    const translationPaths = {
      'ca': `${post?.path?.alias}`,
      'es': `/es${post?.path?.alias}`,
      'en': `/en${post?.path?.alias}`,
    }

    const getCategory = () => {
      let cats = []
      cats = post?.relationships?.field_category.map((cat) => {
        return {'name': cat.name, 'path': cat.path.alias, 'type': 'cat'}
      })
      let tags = []
      tags = post?.relationships?.field_tag.map((tag) => {
        return {'name': tag.name, 'path': tag.path.alias, 'type': 'tag'}
      })
      const catsTags = [...new Set([...cats, ...tags])];
      return catsTags?.map(item => (
        <React.Fragment key={item.name}>
          <a href={item.path} className={item.type}>{item.name}</a>
          {catsTags.indexOf(item) !== catsTags.length - 1 ? ', ' : ''}
        </React.Fragment>
      ));
    }

    const getDayHour = (startDate, endDate) =>{
      const start = moment(startDate);
      const end = moment(endDate);

      const dateFormat = 'DD-MM-YYYY';
      const timeFormat = 'HH:mm';

      if (start.isSame(end, 'day')) {
        // Las fechas son iguales, devuelve solo la primera fecha y hora
        return `${start.format(dateFormat)}, ${start.format(timeFormat)}`;
      } else {
        // Las fechas son diferentes, devuelve ambas fechas y horas
        return `${start.format(dateFormat)}, ${start.format(timeFormat)} - ${end.format(dateFormat)}, ${end.format(timeFormat)}`;
      }
    }

    return (
        <div className="page-event-post">
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post?.langcode}
          >
          <Seo
              title={post?.title}
              lang={post?.langcode}
              description={post?.body?.summary}
              image={post?.relationships?.field_image?.image_style_uri?._653_432}
            />
            <Header
              lang={pageContext?.langcode}
              location={location}
             />
            <section className="section-blog-list-filters m-t-64 p-t-52 hide">
            <div className="container p-0">
             <Filters
                  buttons={[
                    <Button
                     className="btn-all-blog-posts p-0"
                     title="Tots els Posts"
                     wrapperclassName="col-2- p-l-0 p-r-0 all-ministries-wrapper m-r-32"
                     action="/events"
                     />,
                    <Button
                     className="btn-dropdown filter-blog-post"
                     type="dropdown"
                     title="Totes les Categories"
                     options={categories}
                     wrapperclassName="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
                     action="/events"
                     />,
                   ]}
                  //searchQuery={searchQuery}
                  //setSearchQuery={setSearchQuery}
                  action="/events"
                  marginbottom="m-b-43"
                  marginTop="m-t-28"
                  hasSearch={true}
                  searchPlaceholder="Cerca per post"
                  initialQuery={initialQuery}
                  refreshList={refreshList}
                  className="hide"
              />
              </div>
            </section>
            <section className="m-t-64 m-b-32 title-section small-m-t-40 small-m-b-16">
              <div className="container">
                <div className="row small-m-0">
                  <div className="col-12 col-md-10 offset-md-1 p-0">
                    <h1 className={`grey-dark d-flex ${breakpoints.sm ? 'justify-content-center' : 'justify-content-left'} p-t-36 m-b-0 small-m-b-40`}>
                      {post?.title}
                    </h1>
                 </div>
                </div>
              </div>
              </section>
              <section className="interior-content content-section grey-dark">
               <div className="container small-p-l-16">
                <div className="row small-m-0">
                  <div className="col-12 col-md-6 offset-md-1 p-0- m-r-20 p-l-0 p-r-10">
                  {post?.relationships?.field_image &&
                    <div className="img-interior-ministry p-0">
                      <img
                       src={post?.relationships?.field_image?.image_style_uri?._653_432}
                       alt={post?.title}
                       />
                    </div>
                  }

                  <div className="row ribbon-interior m-b-32 small-m-t--16 small-m-b-32">
                    <div className="row small-m-0">

                    <div className="col-md-6 ribbon-line ribbon-element">
                      <RibbonItem
                        icon="icons-32-x-32-export-clock"
                        iconSize={20}
                        className="small-m-b-16"
                        label="Dia i hora"
                        text={getDayHour(post.field_start_time, post.field_end_time)}
                      />
                      <RibbonItem
                        icon="icons-32-x-32-export-tag"
                        iconSize={20}
                        className="small-m-b-16"
                        label="Projecte"
                        text={post?.relationships?.field_project?.title || "--"}
                      />
                    </div>

                    <div className="col-md-6 ribbon-element p-l-35">
                      <RibbonItem
                        icon="icons-32-x-32-export-maps"
                        iconSize={20}
                        className="small-m-b-16"
                        label="On/adreça"
                        text={post?.field_location || "--"}
                      />
                      <RibbonItem
                        icon="icons-32-x-32-export-euro"
                        iconSize={20}
                        className=""
                        iconClassName="euro"
                        label="Preu"
                        text={post?.field_price_session || "Esdeveniment gratuït"}
                      />
                    </div>

                    </div>
                  </div>
                    <div
                      dangerouslySetInnerHTML={{__html: post?.body?.processed}}
                      className="simple-page p-b-80"
                    />
                  </div>
                  <div className="col-12 col-md-3 offset-md-1 sidebar p-0 grey-dark">
                  <div className="box-2 share-social-media m-b-66 small-m-b-64">
                    <SharePostSocialMedia
                      imageUrl={post?.relationships?.field_image?.image_style_uri?._653_432}
                      className="share-this"
                      url={fullUrl}
                      title={post?.title}
                      socialmedia={[
                        'twitter',
                        'facebook',
                        'linkedin',
                        'pinterest'
                      ]}
                      description={post?.body?.summary}
                      boxTitle="Compartir Esdeveniment"
                    />
                  </div>
                  {recommendeds && recommendeds.length > 0 &&
                    <div className="box-3 related-posts small-float small-100 small-m-b-64">
                        <div className="box-title Small-Bold-L---grey-dark m-b-18 ">
                          {"Esdeveniments recomanats"}
                        </div>
                        <Recommended
                          recommendeds={recommendeds}
                          className="similar-items"
                        />
                    </div>
                  }

                  </div>
                </div>
              </div>
              </section>
            <Footer
              lang={pageContext?.langcode}
            />
          </Layout>
        </div>
    )
}

export const Recommended = ({recommendeds, className}) => {

  if(!recommendeds){
    return
  }

  return(
    <div className={className}>
      {recommendeds?.edges?.map(({node}, index) => {
          let description = Utils.trimToN(node?.body?.summary, 75, 3)
          let image = node?.relationships?.field_image?.image_style_uri?._294x192 ? node?.relationships?.field_image?.image_style_uri?._294x192 : 'https://via.placeholder.com/150'
          return (
            <BlogItem
               key={index}
               title={node?.title}
               description={description}
               //date={node?.created}
               path={node?.path?.alias}
               image={image}
               className="similar-item"
            />
          )
      })}
    </div>
  )

}

export const RibbonItem = ({
  className,
  icon,
  iconSize,
  label,
  text,
  iconClassName
  }) => {
  return (
    <div className={className}>
    <div className="row m-b-20">
      <div className={`col-2 col-md-2 p-0 ribbon-icon`}>
        <span>
        <Icon
          icon={icon}
          size={iconSize}
          color="#9a2323"
          className={iconClassName}
        />
        </span>
      </div>
      <div className="col-10 col-md-10 p-0 ribbon-element">
        <div className="ribbon-title">{label}</div>
        <div className="ribbon-info">{text}</div>
      </div>
    </div>
    </div>
  )
}



export const pageQuery = graphql`
query PageEvent ($drupal_internal__nid: Int, $langcode: String!, $currentDate: Date!) {
    nodeEventGoel(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
      drupal_id
      title
      body{
            summary
            processed
            value
      }
      created(locale: $langcode, formatString: "D MMMM Y")
      langcode
      path{
        alias
      }
      field_start_time
      field_end_time
      field_price_session
      field_location
      field_subtitle
      author {
        relationships {
          field_image {
            image_style_uri {
              _653_432
              _120x120
            }
          }
        }
        field_tagline
        field_surname
        field_name
      }
      relationships {
        field_project {
          title
          path {
            alias
          }
          langcode
          drupal_id
        }
        field_image {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            _1103x488
            large
            medium
            thumbnail
            wide
          }
        }
      }
    sticky
    }
    recommendeds: allNodeEventGoel
      (
        limit: 4,
        filter: {
          langcode: {eq: $langcode}
          field_start_time: {gte: $currentDate}
          drupal_internal__nid: {ne: $drupal_internal__nid}
        }
      )
      {
    edges {
      node {
          langcode
            title
            created(formatString: "DD MMMM YYYY")
            body {
              summary
              value
            }
            path {
              alias
            }
            relationships {
              field_image {
                image_style_uri {
                  large
                  medium
                  thumbnail
                  wide
                  _294x192
                  _541x359
                  _653_432
                }
              }
            }
        }
      }
    }
 }
`

export default PageEvent
